<template>
  <div class="adventure-archive">
    <img v-if="currentTab === 1" class="archive-background" src="../../../assets/images/folder-0.svg" alt="探險檔案背景圖">
    <img v-else-if="currentTab === 2" class="archive-background" src="../../../assets/images/folder-1.svg" alt="探險檔案背景圖">
    <img v-else-if="currentTab === 3" class="archive-background" src="../../../assets/images/folder-2.svg" alt="探險檔案背景圖">
    <div v-else>
      <img v-if="currentLanguageKey.includes('ZH_')" class="archive-background" src="../../../assets/images/folder.png" alt="探險檔案背景圖">
      <img v-else class="archive-background" src="../../../assets/images/folder-en.png" alt="探險檔案背景圖">
    </div>
    <div class="archive-tab-container">
      <div class="archive-tab" @click="handleArchiveTabClick(1)"></div>
      <div class="archive-tab" @click="handleArchiveTabClick(2)"></div>
      <div class="archive-tab" @click="handleArchiveTabClick(3)"></div>
    </div>
    <div class="archive-tab-title font-weight-thin" :class="{ 'archive-tab-title-en' : currentLanguageKey.includes('ZH_') === false }">
      <span v-if="currentTab === 1">{{ getTitleByLanguage('歷程紀錄') | changeFirstCharToUpper }}</span>
      <span v-else-if="currentTab === 2">{{ getTitleByLanguage('收藏檔案') | changeFirstCharToUpper }}</span>
      <span v-else-if="currentTab === 3">{{ getTitleByLanguage('問卷調查') | changeFirstCharToUpper }}</span>
    </div>
    <div v-if="currentTab === 1" class="archive-content icon-archive-record" :class="{ 'icon-archive-record-en' : currentLanguageKey.includes('ZH_') === false }">
      <div class="icon-control-box">
        <div class="icon-clear-button" @click="handleClearRecords">
          <img src="../../../assets/images/icon-clear.svg" alt="清除按鈕">
        </div>
        <div class="icon-gift-button" @click="handleGiftRecords">
          <img src="../../../assets/images/icon-gift.svg" alt="兌換按鈕">
        </div>
      </div>
      <div class="icon-archive-record-row" style="margin-top: 2%">
        <div class="icon-archive-record-col">
          <img v-if="archiveRecordData[1]" src="../../../assets/images/icon-archive-fill-record-1.svg" alt />
          <img v-else src="../../../assets/images/icon-archive-record-1.svg" alt />
          <div class="icon-archive-record-checkbox">
            <div class="archive-record-checkbox-container">
              <img v-if="archiveRecordData[1]" src="../../../assets/images/icon-checked.svg" alt>
            </div>
            <span class="font-weight-medium" style="color: yellow">{{ getTitleByLanguage('聆聽15個專家導覽') }}</span>
          </div>
        </div>
        <div class="icon-archive-record-col">
          <img v-if="archiveRecordData[2]" src="../../../assets/images/icon-archive-fill-record-2.svg" alt />
          <img v-else src="../../../assets/images/icon-archive-record-2.svg" alt />
          <div class="icon-archive-record-checkbox">
            <div class="archive-record-checkbox-container">
              <img v-if="archiveRecordData[2]" src="../../../assets/images/icon-checked.svg" alt>
            </div>
            <span class="font-weight-medium">{{ getTitleByLanguage('聆聽30個專家導覽') }}</span>
          </div>
        </div>
        <div class="icon-archive-record-col">
          <img v-if="archiveRecordData[3]" src="../../../assets/images/icon-archive-fill-record-3.svg" alt />
          <img v-else src="../../../assets/images/icon-archive-record-3.svg" alt />
          <div class="icon-archive-record-checkbox">
            <div class="archive-record-checkbox-container">
              <img v-if="archiveRecordData[3]" src="../../../assets/images/icon-checked.svg" alt>
            </div>
            <span class="font-weight-medium">{{ getTitleByLanguage('聆聽全部專家導覽') }}</span>
          </div>
        </div>
      </div>
      <div class="icon-archive-record-row">
        <div class="icon-archive-record-col">
          <img v-if="archiveRecordData[4]" src="../../../assets/images/icon-archive-fill-record-4.svg" alt />
          <img v-else src="../../../assets/images/icon-archive-record-4.svg" alt />
          <div class="icon-archive-record-checkbox">
            <div class="archive-record-checkbox-container">
              <img v-if="archiveRecordData[4]" src="../../../assets/images/icon-checked.svg" alt>
            </div>
            <span class="font-weight-medium" style="color: yellow">{{ getTitleByLanguage('體驗2個互動遊戲') }}</span>
          </div>
        </div>
        <div class="icon-archive-record-col">
          <img v-if="archiveRecordData[5]" src="../../../assets/images/icon-archive-fill-record-5.svg" alt />
          <img v-else src="../../../assets/images/icon-archive-record-5.svg" alt />
          <div class="icon-archive-record-checkbox">
            <div class="archive-record-checkbox-container">
              <img v-if="archiveRecordData[5]" src="../../../assets/images/icon-checked.svg" alt>
            </div>
            <span class="font-weight-medium">{{ getTitleByLanguage('體驗全部互動遊戲') }}</span>
          </div>
        </div>
        <div class="icon-archive-record-col">
          <img v-if="archiveRecordData[6]" src="../../../assets/images/icon-archive-fill-record-6.svg" alt />
          <img v-else src="../../../assets/images/icon-archive-record-6.svg" alt />
          <div class="icon-archive-record-checkbox">
            <div class="archive-record-checkbox-container">
              <img v-if="archiveRecordData[6]" src="../../../assets/images/icon-checked.svg" alt>
            </div>
            <span class="font-weight-medium" style="color: yellow">{{ getTitleByLanguage('完成問卷調查') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentTab === 2" class="archive-content">
      <div :id="`collection_${collectionIndex}`" v-for="(collection, collectionIndex) in collectionList" :key="`collection_${collection.CollectionId}`" class="collections-list-block">
        <div class="collection-area font-weight-regular">
          <span>{{ collection.CollectionNo }}</span>
        </div>
        <div class="collection-title font-weight-regular" @click="goCollection(collectionIndex)">
          <span>{{ getCollectCollectionTitle(collection) }}</span>
        </div>
        <div class="collection-collect">
          <div class="unCollect-btn" @click="handleUnCollect(collection, false)">
            <div class="unCollect"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentTab === 3 && questionnaire" class="archive-content">
      <el-row v-for="(page, pageIndex) in questionnairePages" :key="`Page_${pageIndex}_${page}`">
        <el-row class="q-title">
          <img src="../../../assets/images/icon-stroke.svg" alt="">
          <span>{{ questionnaire[page]['Title'][currentLanguageKey] }}</span>
        </el-row>
        <el-row :id="`${q.Field}`" class="q-content" v-for="(q, qIndex) in questionnaire[page]['QList']" :key="`${page}_Q_${qIndex}`">
          <span
              class="q-content-title"
              :class="{ 'font-weight-light': q['Type'] === 'Message', 'font-weight-medium': q['Type'] !== 'Message' }"
              v-if="q['Title'] && q['Title'][currentLanguageKey] && q['Title'][currentLanguageKey].trim().length > 0">
            {{ q['Title'][currentLanguageKey] }}
          </span>
          <div v-html="getOtherDescHtml(q.OtherDesc)" style="word-break: break-all"></div>
          <div class="q-content-model" v-if="q['Type'] === 'SingleOption'">
            <el-radio-group v-model="questionnaireAns[q.Field].ans" text-color="#07DB95" fill="#07DB95">
              <el-radio v-for="(option, optionIndex) in q['Options']" :key="`${q[currentLanguageKey]}_${optionIndex}`" :label="option[currentLanguageKey]">
                <span>{{ option[currentLanguageKey] }}</span>
                <img
                    v-if="option.HasFile && option.File"
                    :src="`https://homing.ntsec.gov.tw/files/${option.File}`"
                    style="display: block; margin: 15px 0 0 24px; width: 60%"
                    alt="選項圖片">
              </el-radio>
              <div v-if="q['OptionOther'][currentLanguageKey] && q['OptionOther'][currentLanguageKey].trim().length > 0" style="display: block">
                <el-radio
                    :label="q['OptionOther'][currentLanguageKey]"
                    style="margin-right: 10px">
                  {{ q['OptionOther'][currentLanguageKey] }}</el-radio>
                <el-input
                    type="text"
                    class="other-input"
                    v-model="questionnaireAns[q.Field].other"
                    :disabled="questionnaireAns[q.Field].ans !== q['OptionOther'][currentLanguageKey]"
                    :placeholder="`${ getTitleByLanguage('請填寫') }`"></el-input>
              </div>
            </el-radio-group>
          </div>
          <div class="q-content-model" v-else-if="q['Type'] === 'MultiOption'">
            <el-checkbox-group v-model="questionnaireAns[q.Field].ans" text-color="#07DB95" fill="#07DB95">
              <el-checkbox v-for="(option, optionIndex) in q['Options']" :key="`${q[currentLanguageKey]}_${optionIndex}`" :label="option[currentLanguageKey]">{{ option[currentLanguageKey] }}</el-checkbox>
              <div v-if="q['OptionOther'][currentLanguageKey] && q['OptionOther'][currentLanguageKey].trim().length > 0" style="display: block">
                <el-checkbox
                    :label="q['OptionOther'][currentLanguageKey]"
                    style="margin-right: 10px">
                  {{ q['OptionOther'][currentLanguageKey] }}</el-checkbox>
                <el-input
                    type="text"
                    class="other-input"
                    v-model="questionnaireAns[q.Field].other"
                    :disabled="questionnaireAns[q.Field].ans.includes(q['OptionOther'][currentLanguageKey]) === false"
                    :placeholder="`${ getTitleByLanguage('請填寫') }`"></el-input>
              </div>
            </el-checkbox-group>
          </div>
          <div class="q-content-model" v-else-if="q['Type'] === 'ShortAnswer'">
            <el-input v-model="questionnaireAns[q.Field].ans" type="textarea" :rows="5" :autosize="false" />
          </div>
          <div class="q-content-model" v-else-if="q['Type'] === 'Answer'">
            <el-input v-model="questionnaireAns[q.Field].ans" type="text" />
          </div>
          <div class="q-content-model" v-else-if="q['Type'] === 'Satisfaction'">
            <el-divider></el-divider>
            <el-row :gutter="10" style="text-align: center">
              <el-col :offset="9" :span="3">{{ getTitleByLanguage('非常') | changeFirstCharToUpper }}<br>
                {{ getTitleByLanguage('滿意') | changeFirstCharToUpper }}</el-col>
              <el-col :span="3">{{ getTitleByLanguage('滿意') | changeFirstCharToUpper }}</el-col>
              <el-col :span="3">{{ getTitleByLanguage('普通') | changeFirstCharToUpper }}</el-col>
              <el-col :span="3">{{ getTitleByLanguage('不滿意') | changeFirstCharToUpper }}</el-col>
              <el-col :span="3">{{ getTitleByLanguage('非常') | changeFirstCharToUpper }}<br>
                {{ getTitleByLanguage('不滿意') | changeFirstCharToUpper }}</el-col>
            </el-row>
            <el-row :gutter="10" style="text-align: center" v-for="(option, optionIndex) in q['Options']" :key="`${q[currentLanguageKey]}_${optionIndex}`">
              <el-col :span="9" style="font-size: 1.5rem; line-height: 40px; text-align: left">
                <span>{{ option[currentLanguageKey] }}</span>
              </el-col>
              <el-col :offset="(!option[currentLanguageKey] || option[currentLanguageKey].trim().length === 0) ? 9 : 0" class="satisfaction-option" :span="3">
                <el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="非常滿意">
                </el-radio>
              </el-col>
              <el-col class="satisfaction-option" :span="3"><el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="滿意"></el-radio></el-col>
              <el-col class="satisfaction-option" :span="3"><el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="普通"></el-radio></el-col>
              <el-col class="satisfaction-option" :span="3"><el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="不滿意"></el-radio></el-col>
              <el-col class="satisfaction-option" :span="3"><el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="非常不滿意"></el-radio></el-col>
            </el-row>
          </div>
          <div class="q-content-model" v-else-if="q['Type'] === 'Importance'">
            <el-divider></el-divider>
            <el-row :gutter="10" style="text-align: center">
              <el-col :offset="9" :span="3">{{ getTitleByLanguage('非常') | changeFirstCharToUpper }}<br>
                {{ getTitleByLanguage('重要') | changeFirstCharToUpper }}</el-col>
              <el-col :span="3">{{ getTitleByLanguage('重要') | changeFirstCharToUpper }}</el-col>
              <el-col :span="3">{{ getTitleByLanguage('普通') | changeFirstCharToUpper }}</el-col>
              <el-col :span="3">{{ getTitleByLanguage('不重要') | changeFirstCharToUpper }}</el-col>
              <el-col :span="3">{{ getTitleByLanguage('非常') | changeFirstCharToUpper }}<br>
                {{ getTitleByLanguage('不重要') | changeFirstCharToUpper }}</el-col>
            </el-row>
            <el-row :gutter="10" style="text-align: center" v-for="(option, optionIndex) in q['Options']" :key="`${q[currentLanguageKey]}_${optionIndex}`">
              <el-col :span="9" style="font-size: 1.5rem; line-height: 40px; text-align: left">
                <span>{{ option[currentLanguageKey] }}</span>
              </el-col>
              <el-col class="satisfaction-option" :span="3"><el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="非常重要"></el-radio></el-col>
              <el-col class="satisfaction-option" :span="3"><el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="重要"></el-radio></el-col>
              <el-col class="satisfaction-option" :span="3"><el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="普通"></el-radio></el-col>
              <el-col class="satisfaction-option" :span="3"><el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="不重要"></el-radio></el-col>
              <el-col class="satisfaction-option" :span="3"><el-radio v-model="questionnaireAns[option.Field].ans" text-color="#07DB95" fill="#07DB95" label="非常不重要"></el-radio></el-col>
            </el-row>
          </div>
<!--          <div class="q-content-model" v-else-if="q['Type'] === 'Message'">-->
<!--            <span>{{ q['Title'][currentLanguageKey] }}</span>-->
<!--          </div>-->
        </el-row>
      </el-row>
      <div class="archive-submit-button font-weight-bold" @click="handleSubmitArchive">
        <span>{{ getTitleByLanguage('送出') | changeFirstCharToUpper }}</span>
        <img src="../../../assets/images/icon-arrow-right.svg" alt="送出">
      </div>
    </div>
    <div v-else class="archive-content" style="overflow: hidden" @click="currentTab = 1"></div>
  </div>
</template>

<script>
import AdventureArchiveMixin from "@/mixins/adventure-archive-mixin";
export default {
  name: "AdventureArchiveIndex",
  mixins: [AdventureArchiveMixin]
}
</script>

<style lang="scss" scoped>
.adventure-archive {
  position: relative;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #20272D;
  overflow: hidden;
  .archive-background {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 120vw;
    height: auto;
  }
  .archive-tab-container {
    position: absolute;
    right: 65px;
    top: 30px;
    width: calc(120vw / 20 * 11);
    height: 100px;
    //border: 1px white solid;
    display: flex;
    .archive-tab {
      width: 33.333%;
      height: 100%;
      //border: 1px white solid;
    }
  }
  .archive-tab-title {
    position: absolute;
    left: 5vw;
    top: 300px;
    color: #07DB95;
    background: #080A0D;
    line-height: 40px;
    font-size: 2.3rem;
    padding: 0 5px;
    letter-spacing: 0.58rem;
  }
  .archive-tab-title-en {
    letter-spacing: 0.2rem;
  }
  .archive-content {
    position: absolute;
    right: 6vw;
    top: 13vw;
    left: 20vw;
    bottom: 0;
    padding: 50px 50px 100px 100px;
    overflow-y: scroll;
    overflow-x: hidden;
    //border: 1px solid white;
    color: #C8DCCB;
    text-align: left;
    .q-title {
      font-size: 1.8rem;
      line-height: 30px;
      padding: 30px 80px;
      font-weight: 100 !important;
      img {
        width: 80px;
        margin-left: -100px;
        float: left;
      }
      span {
        display: block;
      }
    }
    .q-content {
      font-size: 1.2rem;
      font-weight: 100 !important;
      line-height: 30px;
      padding: 20px 80px;
      .q-content-title {
        padding: 5px 0;
      }
      .q-content-model {
        margin: 10px 0;
      }
    }
    .archive-submit-button {
      font-size: 2rem;
      width: fit-content;
      margin: auto;
      img {
        margin-left: 20px;
      }
    }
  }
  .collections-list-block {
    display: flex;
    height: 70px;
    color: #CCDBCC;
    padding: 40px;
    cursor: pointer;
    background: #1B2228;
    .collection-area {
      width: 20%;
      line-height: 70px;
      text-align: center;
      span {
        margin-right: 5%;
        font-weight: bolder;
        font-size: 1.5rem;
      }
    }
    .collection-title {
      width: 60%;
      text-align: left;
      display: flex; /* ★ */
      align-items: center; /* ★ */
      span {
        font-size: 1.5rem;
      }
    }
    .collection-collect {
      width: 20%;
      .unCollect-btn {
        width: 60px;
        height: 100%;
        display: flex;
        align-items: center;
        .unCollect {
          width: 33px;
          height: 3px;
          background-color: #07DB95;
        }
      }
      img {
        height: 46px;
        margin: 12px auto;
        cursor: pointer;
        pointer-events: auto;
      }
    }
  }
  .collection-highlight {
    background-color: #5E927A;
  }
  .icon-archive-record {
    overflow: hidden;
    padding: 20px 50px;
    .icon-archive-record-row {
      width: 85%;
      height: 48%;
      display: flex;
      justify-content: space-between;
      .icon-archive-record-col {
        display: flex;
        flex-direction: column;
        width: 30%;
        img {
          flex: 1;
        }
        .icon-archive-record-checkbox {
          display: flex;
          line-height: 20px;
          padding: 20px 0;
          justify-content: center;
          .archive-record-checkbox-container {
            width: 15px;
            height: 15px;
            background-color: #344448;
            margin: 2.5px;
            img {
              width: 10px;
              height: 10px;
              object-position: center;
              object-fit: contain;
              margin: 2.5px;
            }
          }
          span {
            font-size: 20px;
            margin-left: 10px;
          }
        }
      }
    }
    .icon-control-box {
      float: right;
      width: 70px;
      height: 200px;
      .icon-clear-button {
        width: 70px;
        height: 70px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          user-select: none;
          pointer-events: none;
        }
      }
      .icon-gift-button {
        width: 50px;
        height: 50px;
        margin: 20px 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          user-select: none;
          pointer-events: none;
        }
      }
    }
  }
  .icon-archive-record-en {
    .icon-archive-record-checkbox {
      span {
        font-size: 12px !important;
      }
    }
  }
}
</style>

<style lang="scss">
.el-radio {
  padding: 10px;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #07DB95;
  background: #07DB95;
}
.el-radio__input.is-checked .el-radio__inner:after {
  width: 0;
  height: 0;
}
.el-radio__label {
  font-size: 1.4rem;
  font-weight: 100 !important;
  color: #CCDBCC;
}
.el-radio__input.is-checked+.el-radio__label {
  color: #CCDBCC;
}
.el-checkbox {
  padding: 10px;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: #07DB95;
  background: #07DB95;
}
.el-checkbox__input.is-checked .el-checkbox__inner:after {
  width: 0;
  height: 0;
}
.el-checkbox__label {
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 100 !important;
  color: #CCDBCC;
  white-space: pre-line;
}
.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #CCDBCC;
}
.satisfaction-option {
  text-align: center;
  .el-radio__label {
    display: none !important;
  }
}
.other-input {
  width: 150px;
  color: white;
  font-size: 1.2rem;
  font-weight: 100 !important;
  input {
    background-color: #475560;
    border: 0;
    border-radius: 0;
    height: 40px !important;
    line-height: 40px !important;
    color: white;
    font-weight: 100;
  }
}

.el-textarea {
  color: #CCDBCC;
  font-size: 1.2rem;
  font-weight: 100 !important;
  textarea {
    background-color: #475560 !important;
    border: 0;
    border-radius: 0;
    color: white;
    font-weight: 700 !important;
  }
}

.el-input {
  color: #CCDBCC;
  font-size: 1.2rem;
  font-weight: 100 !important;
  input {
    background-color: #475560 !important;
    border: 0;
    border-radius: 0;
    color: white;
    font-weight: 100 !important;
    height: 40px;
    line-height: 40px;
  }
}
</style>
