import arMixin from "@/mixins/ar-mixin";

export default {
  mixins: [arMixin],
  mounted() {
  },
  methods: {
    getTitleByLanguage(title) {
      switch (title) {
        case '內臟':
          return this.$store.getters.languageKey.includes('ZH_') ? '內臟' : 'Viscera'
        case '卵':
          return this.$store.getters.languageKey.includes('ZH_') ? '卵' : 'Egg'
      }
    },
    handleOrientationChange() {
        if (window.orientation === 0 || window.orientation === 180) {
          // alert('請將裝置橫放掃描')
        } else {
            // this.$router.push({ name: 'Landscape' })
          alert('請將裝置直立掃描')
        }
    }
  }
}
