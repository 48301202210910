import ArMixin from "@/mixins/ar-mixin";

export default {
  mixins: [ArMixin],
  data() {
    return {
      activeBird: undefined,
      showBirdContent: false,
      activeFood: undefined
    }
  },
  computed: {
    getInfoTitle() {
      if (this.showBirdContent) {
        if (this.currentLanguageKey.includes('ZH_')) {
          return '吃吃看！'
        } else {
          return 'Take a look at the beaks of omnivorous birds.'
        }
      }
      switch (this.activeBird) {
        case 'birdA':
          if (this.currentLanguageKey.includes('ZH_')) {
            return '雙角犀鳥'
          } else {
            return 'Great Hornbills'
          }
        case 'birdB':
          if (this.currentLanguageKey.includes('ZH_')) {
            return '栗翅鷹'
          } else {
            return 'Harris’s Hawks'
          }
        case 'birdE':
          if (this.currentLanguageKey.includes('ZH_')) {
            return '紅尾伯勞'
          } else {
            return 'Brown Shrikes'
          }
        case 'birdF':
          if (this.currentLanguageKey.includes('ZH_')) {
            return '黑頭翡翠'
          } else {
            return 'Black-capped Kingfishers'
          }
        default:
          if (this.currentLanguageKey.includes('ZH_')) {
            return '鳥喙的構造'
          } else {
            return 'Bird’s beak'
          }
      }
    },
    getInfoDesc() {
      if (this.showBirdContent) {
        if (this.currentLanguageKey.includes('ZH_')) {
          return '根據雜食型鳥喙的特色，選擇他們喜愛的食物'
        } else {
          return 'Feed them according to the characteristics of their beaks!\n'
        }
      }
      switch (this.activeBird) {
        case 'birdA':
          if (this.currentLanguageKey.includes('ZH_')) {
            return '雜食型，以樹上果實、昆蟲、雛鳥、蜥蜴為食。'
          } else {
            return 'Omnivorous birds that feed on fruits on trees, insects, fledges, and lizards.'
          }
        case 'birdB':
          if (this.currentLanguageKey.includes('ZH_')) {
            return '肉食型猛禽，以小型鳥類、蜥蜴、小型哺乳動物、大型昆蟲為食。'
          } else {
            return 'Carnivorous birds of prey that feed on small birds, lizards, small mammals, and large insects.'
          }
        case 'birdE':
          if (this.currentLanguageKey.includes('ZH_')) {
            return '肉食型，以蚱蜢、蜻蜓、青蛙、蜥蜴等小型動物為食。'
          } else {
            return 'Omnivorous birds that feed on small fishes and small animals.'
          }
        case 'birdF':
          if (this.currentLanguageKey.includes('ZH_')) {
            return '雜食型，以小魚、小動物為食。'
          } else {
            return 'Carnivorous birds that feed on grasshoppers, dragonflies, frogs, and lizards.'
          }
        default:
          if (this.currentLanguageKey.includes('ZH_')) {
            return '選擇鳥喙，觀看因習性在覓食行為演化而來的特色'
          } else {
            return 'Select a beak to learn more about its characteristics and how the bird’s diet has led its beak to evolve.'
          }
      }
    }
  },
  mounted() {
    window.addEventListener('orientationchange', this.handleOrientationChange);
  },
  methods: {
    handleChangeActiveBird(bird) {
      this.activeBird = bird
    },
    handleCheckFood(food) {
      this.activeFood = food
    },
    handleOrientationChange() {
      if (window.orientation === 0 || window.orientation === 180) {
        alert('請將裝置橫放掃描')
      } else {
        // this.$router.push({ name: 'Landscape' })
      }
    }
  }
}
