<template>
  <div class="way-home-0">
    <div class="desktop-flashlight-container">
      <div class="desktop-flashlight-title font-weight-medium">
        <span>{{ getTitle }}</span>
      </div>
      <div class="desktop-flashlight-sub-title font-weight-medium">
        <span>{{ getSubTitle }}</span>
      </div>
      <div class="desktop-flashlight-enter-button font-weight-bold">
        <span>{{ getEnterBtnTitle }}</span>
        <img src="../../../assets/images/icon-arrow-right-black.svg" alt>
      </div>
    </div>
    <img
        id="flashlight"
        class="flashlight"
        src="../../../assets/images/flashlight.svg"
        alt="flashlight"
        :style="{
            left: flashlightPointData.Left + 'px',
            top: flashlightPointData.Top + 'px',
            width: flashlightPointData.Width + 'px',
            height: flashlightPointData.Height + 'px'
          }"
        @load="flashlightLoaded">
    <div
        style="position: absolute; border: 1px solid white; width: 200px; height: 100px; pointer-events: none; display: none"
        :style="{
            left: (flashlightPointData.Left + (flashlightPointData.Width / 2) - 100) + 'px',
            top: (flashlightPointData.Top + (flashlightPointData.Height / 2) - 50) + 'px',
          }"
    ></div>
    <div class="enter-button" @click="goNext" />
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'

export default {
  name: 'WayHome0',
  components: {},
  data() {
    return {
      tapDown: {
        x: 0,
        y: 0,
        centerX: 0,
        centerY: 0,
        distance: 0,
        scale: 1,
        rotate: 0,
        touchStartAngle: 0,
        startAngle: 0
      },
      flashlightPointData: {
        Left: -document.body.offsetWidth / 2,
        Top: -document.body.offsetHeight / 2,
        Width: document.body.offsetWidth * 2,
        Height: document.body.offsetHeight * 2,
      },
      originFlashlightPointData: null,
      bodyOffsetWidth: document.body.offsetWidth,
      bodyOffsetHeight: document.body.offsetHeight,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  computed: {
    getTitle() {
      if (this.currentLanguageKey.includes('ZH_')) {
        return '什麼樣的家園風景會觸動你的內心深處？'
      } else {
        return 'How does your homeland tug at your heartstrings?'
      }
    },
    getSubTitle() {
      if (this.currentLanguageKey.includes('ZH_')) {
        return '透過家園守護者的訴說，探索生機盎然的家園風景。'
      } else {
        return 'Delve into the rich tapestry of our vibrant homeland through the tales told by its guardians.'
      }
    },
    getEnterBtnTitle() {
      if (this.currentLanguageKey.includes('ZH_')) {
        return '尋找'
      } else {
        return 'Find'
      }
    },
  },
  mounted() {
    this.bodyOffsetWidth = document.body.offsetWidth
    this.bodyOffsetHeight = document.body.offsetHeight
    this.flashlightPointData = {
      Left: -document.body.offsetWidth * 0.8,
      Top: -document.body.offsetHeight * 0.5,
      Width: document.body.offsetWidth * 2,
      Height: document.body.offsetHeight * 2,
    }
    console.log(`screen ${this.bodyOffsetWidth} x ${this.bodyOffsetHeight}`)
    console.log(`body ${document.body.offsetWidth} x ${document.body.offsetHeight}`)
  },
  beforeDestroy() {
    const photoElement = document.getElementById('flashlight')
    if (photoElement) {
      photoElement.removeEventListener('mousemove')
      photoElement.removeEventListener('mouseup')
      photoElement.removeEventListener('mouseleave')
      photoElement.removeEventListener('mousedown')
      photoElement.removeEventListener('touchstart')
      photoElement.removeEventListener('touchmove')
      photoElement.removeEventListener('touchend')
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    goNext() {
      // console.log('go')
      // console.log(`${this.flashlightPointData.Left}, ${this.flashlightPointData.Width * 0.5 * -0.39}`)
      // console.log(`${this.flashlightPointData.Top}, ${this.flashlightPointData.Height * 0.5 * -0.45}`)
      if (!(this.flashlightPointData.Left > this.flashlightPointData.Width * 0.5 * -0.39 &&
          this.flashlightPointData.Top > this.flashlightPointData.Height * 0.5 * -0.45)) {
        router.push({
          name: 'DesktopWayHome1'
        })
      }
    },
    flashlightLoaded() {
      const photoElement = document.getElementById('flashlight')
      photoElement.addEventListener('mousemove', this.photoTouchMove, { passive: false })
      photoElement.addEventListener('mouseup', this.photoTouchEnd, { passive: false })
      photoElement.addEventListener('mouseleave', this.photoTouchEnd, { passive: false })
      photoElement.addEventListener('mousedown', this.photoTouchStart, { passive: false })
      photoElement.addEventListener('touchstart', this.photoTouchStart, { passive: false })
      photoElement.addEventListener('touchmove', this.photoTouchMove, { passive: false })
      photoElement.addEventListener('touchend', this.photoTouchEnd, { passive: false })
    },
    photoTouchStart (e) {
      e.preventDefault()
      if (e.touches && e.touches.length === 1) {
        this.tapDown.x = e.touches[0].pageX
        this.tapDown.y = e.touches[0].pageY
      } else if (e.pageX) {
        this.tapDown.x = e.pageX
        this.tapDown.y = e.pageY
      }
      let flashlightCenterX = this.flashlightPointData.Left + (this.flashlightPointData.Width / 2);
      let flashlightCenterY = this.flashlightPointData.Top + (this.flashlightPointData.Height / 2);
      if (Math.abs(this.tapDown.x - flashlightCenterX) < (this.bodyOffsetWidth * 0.18 / 2) && Math.abs(this.tapDown.y - flashlightCenterY) < (this.bodyOffsetHeight * 0.12 / 2)) {
        this.originFlashlightPointData = JSON.parse(JSON.stringify(this.flashlightPointData))
      } else {
        this.originFlashlightPointData = null
      }
    },
    photoTouchMove (e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.originFlashlightPointData === null) {
        return
      }
      let updateLeft = 0
      let updateTop = 0
      if (e.touches && e.touches.length === 1) {
        updateLeft = this.originFlashlightPointData.Left + (e.touches[0].pageX - this.tapDown.x)
        updateTop = this.originFlashlightPointData.Top + (e.touches[0].pageY - this.tapDown.y)
      } else if (e.pageX) {
        updateLeft = this.originFlashlightPointData.Left + (e.pageX - this.tapDown.x)
        updateTop = this.originFlashlightPointData.Top + (e.pageY - this.tapDown.y)
      }
      if ((updateLeft + (this.flashlightPointData.Width / 2) - (this.bodyOffsetWidth * 0.18 / 2)) >= 0 &&
          (updateLeft + (this.flashlightPointData.Width / 2) + (this.bodyOffsetWidth * 0.18 / 2)) <= this.bodyOffsetWidth &&
          (updateTop + (this.flashlightPointData.Height / 2) - (this.bodyOffsetHeight * 0.12 / 2)) >= 0 &&
          (updateTop + (this.flashlightPointData.Height / 2) + (this.bodyOffsetHeight * 0.12 / 2)) <= this.bodyOffsetHeight
      ) {
        // console.log('photoTouchMove update')
        this.flashlightPointData.Left = updateLeft
        this.flashlightPointData.Top = updateTop
        // console.log(JSON.stringify(this.flashlightPointData))
      }
    },
    photoTouchEnd (e) {
      e.preventDefault()
      this.originFlashlightPointData = null
    }
  }
}
</script>

<style lang="scss" scoped>
.way-home-0 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../../../assets/images/way-home-0-bg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #07DB95;
  overflow: hidden;
  .desktop-flashlight-container {
    position: absolute;
    left: 20vw;
    right: 0;
    top: 15vh;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 70vw;
    justify-content: center;
    text-align: left;
    .desktop-flashlight-title {
      font-size: 1.6rem;
      color: #20272D;
      line-height: 2.2rem;
    }
    .desktop-flashlight-sub-title {
      font-size: 1.6rem;
      color: #20272D;
      line-height: 2.2rem;
    }
    .desktop-flashlight-enter-button {
      text-align: right;
      font-size: 1.6rem;
      color: #1F262D;
      margin: 40px 10vw 0 10vw;
      height: 50px;
      line-height: 50px;
      animation: fade 1s infinite;
      -webkit-animation: fade 1s infinite;
      img {
        margin: 8px 50px 8px 50px;
      }
    }
  }
  .enter-button {
    position: absolute;
    width: 300px;
    height: 80px;
    right: 13vw;
    bottom: 32vh;
  }
  .flashlight {
    position: absolute;
    left: -50%;
    right: -50%;
    top: -50%;
    bottom: -50%;
    width: 200%;
    height: 200%;
    object-fit: cover;
    //display: none;
  }
}
</style>
