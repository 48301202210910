<template>
  <div class="desktop-welcome-index" @click="playAudio">
    <div class="left-block">
      <div class="intercom-container">
        <img :src="intercomList[intercomIndex]" alt="對講機">
        <div class="up-control" @click.stop="changeIntercom(-1)"></div>
        <div class="down-control" @click.stop="changeIntercom(1)"></div>
        <div class="enter-control" @click.stop="goNext"></div>
      </div>
    </div>
    <div class="right-block">
      <img src="../../assets/images/welcome_logo.svg" alt="LOGO">
    </div>
    <div class="lang-icon font-weight-bold" @click.stop="handleChangeLang" style="user-select: none">
      <span v-if="language === 'ZH_TW'">中</span>
      <span v-else-if="language === 'EN'">EN</span>
      <span v-else-if="language === 'ZH_Hokkien'">台</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../router'
import WelcomeMixin from "@/mixins/welcome-mixin";

export default {
  name: 'Welcome',
  mixins: [WelcomeMixin],
  components: {
  },
  computed: {
    language: function () {
      return this.$store.getters.languageKey
    }
  },
  data() {
    return {
      // intercomList: [
      //   require('../../assets/images/welcome_intercom1.svg'),
      //   require('../../assets/images/welcome_intercom2.svg'),
      //   require('../../assets/images/welcome_intercom3.svg'),
      //   require('../../assets/images/welcome_intercom4.svg'),
      // ],
      // intercomIndex: 0,
      // welcomePlay: undefined
    }
  },
  // destroyed() {
  //   console.log('destroyed')
  //   if (this.welcomePlay) {
  //     this.welcomePlay.pause()
  //   }
  // },
  methods: {
    // playAudio() {
    //   console.log('@click="playAudio"')
    //   this.welcomePlay = new Audio('/welcome.mp3')
    //   this.welcomePlay.play()
    // },
    goNext () {
      router.push({
        name: 'DesktopMainMenu'
      })
    },
    // changeIntercom(index) {
    //   this.intercomIndex = ((this.intercomIndex + index) + this.intercomList.length) % this.intercomList.length
    // },
    // handleChangeLang() {
    //   if (this.language === 'ZH_TW') {
    //     this.$store.dispatch('SetupLanguage', 'EN')
    //   } else {
    //     this.$store.dispatch('SetupLanguage', 'ZH_TW')
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.desktop-welcome-index {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #07DB95;
  background-image: url("../../assets/images/bg.png");
  background-size: cover;
  overflow: hidden;
  .left-block {
    position: relative;
    width: 40%;
    height: 100%;
    .intercom-container {
      position: relative;
      height: 100%;
      margin-top: 15%;
      width: 46.5vh;
      float: right;
    }
    img {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .up-control {
      position: absolute;
      left: 12%;
      top: 62%;
      width: 27%;
      height: 100px;
      margin: auto;
      cursor: pointer;
      //background-color: rgba(255,255,255,0.6);
    }
    .down-control {
      position: absolute;
      left: 39%;
      top: 62%;
      width: 25%;
      height: 100px;
      margin: auto;
      cursor: pointer;
      //background-color: rgba(255,255,255,0.6);
    }
    .enter-control {
      position: absolute;
      left: 64%;
      top: 62%;
      width: 27%;
      height: 100px;
      margin: auto;
      cursor: pointer;
      //background-color: rgba(255,255,255,0.6);
    }
  }
  .right-block {
    position: relative;
    width: 60%;
    height: 100%;
    overflow: hidden;
    img {
      position: absolute;
      //left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 120%;
      margin: auto;
    }
  }
  .lang-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 80px;
    height: 80px;
    cursor: pointer;
    background: url("../../assets/images/icon-lang.svg");
    background-position: center;
    background-repeat: no-repeat;
    color: #07DB95;
    font-size: 1.5rem;
    line-height: 80px;
  }
}
</style>
