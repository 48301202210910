<template>
  <div class="mobile-way-home-0">
    <div class="mobile-flashlight-container" :class="{ 'mobile-flashlight-container-en': currentLanguageKey.includes('ZH_') === false }">
      <div class="mobile-flashlight-title font-weight-medium">
        <span>{{ getTitleByLanguage('什麼樣家園的風景') }}<br>{{ getTitleByLanguage('會觸動你的內心深處？') }}</span>
      </div>
      <div class="mobile-flashlight-sub-title font-weight-light">
        <span>{{ getTitleByLanguage('透過家園守護者的訴說，') }}<br>{{ getTitleByLanguage('重新探索生機盎然的家園風景') }}</span>
      </div>
      <div class="mobile-flashlight-enter-button font-weight-bold">
        <span>{{ getTitleByLanguage('尋找') }}</span>
        <img src="../../../assets/images/icon-arrow-right-black.svg" alt>
      </div>
    </div>
    <img
        id="flashlight"
        class="flashlight"
        src="../../../assets/images/flashlight-mobile.svg"
        alt="flashlight"
        :style="{
            left: flashlightPointData.Left + 'px',
            top: flashlightPointData.Top + 'px',
            width: flashlightPointData.Width + 'px',
            height: flashlightPointData.Height + 'px'
          }"
        @load="flashlightLoaded">
    <div
        class="enter-button"
        @click="goNext"
    >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'

export default {
  name: 'WayHome0',
  components: {},
  data() {
    return {
      tapDown: {
        x: 0,
        y: 0,
        centerX: 0,
        centerY: 0,
        distance: 0,
        scale: 1,
        rotate: 0,
        touchStartAngle: 0,
        startAngle: 0
      },
      flashlightPointData: {
        Left: -document.body.offsetWidth / 2,
        Top: -document.body.offsetHeight / 2,
        Width: document.body.offsetWidth * 2,
        Height: document.body.offsetHeight * 2,
      },
      originFlashlightPointData: null,
      bodyOffsetWidth: document.body.offsetWidth,
      bodyOffsetHeight: document.body.offsetHeight,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  mounted() {
    this.bodyOffsetWidth = document.body.offsetWidth
    this.bodyOffsetHeight = document.body.offsetHeight
    this.flashlightPointData = {
      Left: -document.body.offsetWidth * 0.5,
      Top: -document.body.offsetHeight * 0.2,
      Width: document.body.offsetWidth * 2,
      Height: document.body.offsetHeight * 2,
    }
    console.log(`screen ${this.bodyOffsetWidth} x ${this.bodyOffsetHeight}`)
    console.log(`body ${document.body.offsetWidth} x ${document.body.offsetHeight}`)
  },
  beforeDestroy() {
    const photoElement = document.getElementById('flashlight')
    if (photoElement) {
      photoElement.removeEventListener('mousemove')
      photoElement.removeEventListener('mouseup')
      photoElement.removeEventListener('mouseleave')
      photoElement.removeEventListener('mousedown')
      photoElement.removeEventListener('touchstart')
      photoElement.removeEventListener('touchmove')
      photoElement.removeEventListener('touchend')
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    goNext() {
      router.push({
        name: 'MobileWayHome1'
      })
    },
    flashlightLoaded() {
      const photoElement = document.getElementById('flashlight')
      photoElement.addEventListener('mousemove', this.photoTouchMove, { passive: false })
      photoElement.addEventListener('mouseup', this.photoTouchEnd, { passive: false })
      photoElement.addEventListener('mouseleave', this.photoTouchEnd, { passive: false })
      photoElement.addEventListener('mousedown', this.photoTouchStart, { passive: false })
      photoElement.addEventListener('touchstart', this.photoTouchStart, { passive: false })
      photoElement.addEventListener('touchmove', this.photoTouchMove, { passive: false })
      photoElement.addEventListener('touchend', this.photoTouchEnd, { passive: false })
    },
    photoTouchStart (e) {
      e.preventDefault()
      if (e.touches && e.touches.length === 1) {
        this.tapDown.x = e.touches[0].pageX
        this.tapDown.y = e.touches[0].pageY
      } else if (e.pageX) {
        this.tapDown.x = e.pageX
        this.tapDown.y = e.pageY
      }
      let flashlightCenterX = this.flashlightPointData.Left + (this.flashlightPointData.Width / 2);
      let flashlightCenterY = this.flashlightPointData.Top + (this.flashlightPointData.Height / 2);
      if (Math.abs(this.tapDown.x - flashlightCenterX) < (this.bodyOffsetWidth * 0.18 / 2) && Math.abs(this.tapDown.y - flashlightCenterY) < (this.bodyOffsetHeight * 0.12 / 2)) {
        this.originFlashlightPointData = JSON.parse(JSON.stringify(this.flashlightPointData))
      } else {
        this.originFlashlightPointData = null
      }
    },
    photoTouchMove (e) {
      if (this.originFlashlightPointData === null) {
        return
      }
      console.log('photoTouchMove')
      let updateLeft = 0
      let updateTop = 0
      if (e.touches && e.touches.length === 1) {
        updateLeft = this.originFlashlightPointData.Left + (e.touches[0].pageX - this.tapDown.x)
        updateTop = this.originFlashlightPointData.Top + (e.touches[0].pageY - this.tapDown.y)
      } else if (e.pageX) {
        updateLeft = this.originFlashlightPointData.Left + (e.pageX - this.tapDown.x)
        updateTop = this.originFlashlightPointData.Top + (e.pageY - this.tapDown.y)
      }
      console.log(`updateLeft: ${updateLeft}, updateTop: ${updateTop}`)

      if ((updateLeft + (this.flashlightPointData.Width / 2) - (this.bodyOffsetWidth * 0.18 / 2)) >= 0 &&
          (updateLeft + (this.flashlightPointData.Width / 2) + (this.bodyOffsetWidth * 0.18 / 2)) <= this.bodyOffsetWidth &&
          (updateTop + (this.flashlightPointData.Height / 2) - (this.bodyOffsetHeight * 0.12 / 2)) >= (this.bodyOffsetHeight / 3) &&
          (updateTop + (this.flashlightPointData.Height / 2) + (this.bodyOffsetHeight * 0.12 / 2)) <= this.bodyOffsetHeight
      ) {
        console.log('photoTouchMove update')
        this.flashlightPointData.Left = updateLeft
        this.flashlightPointData.Top = updateTop
        console.log(JSON.stringify(this.flashlightPointData))
      }
    },
    photoTouchEnd () {
      console.log('photoTouchEnd')
      this.originFlashlightPointData = null
    },
    getTitleByLanguage(title) {
      switch (title) {
        case '什麼樣家園的風景':
          return this.currentLanguageKey.includes('ZH_') ? '什麼樣家園的風景' : 'How does your homeland'
        case '會觸動你的內心深處？':
          return this.currentLanguageKey.includes('ZH_') ? '會觸動你的內心深處？' : 'tug at your heartstrings?'
        case '透過家園守護者的訴說，':
          return this.currentLanguageKey.includes('ZH_') ? '透過家園守護者的訴說，' : 'Delve into the rich tapestry of our'
        case '重新探索生機盎然的家園風景':
          return this.currentLanguageKey.includes('ZH_') ? '重新探索生機盎然的家園風景' : 'vibrant homeland through the tales told by its guardians.'
        case '尋找':
          return this.currentLanguageKey.includes('ZH_') ? '尋找' : 'Find'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-way-home-0 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../../../assets/images/way-home-0-bg-mobile.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #07DB95;
  overflow: hidden;
  .mobile-flashlight-container {
    position: absolute;
    left: 0;
    right: 0;
    //top: 0;
    bottom: 45vh;
    width: 73%;
    min-width: 285px;
    margin: auto;
    display: flex;
    flex-direction: column;
    .mobile-flashlight-title {
      color: #20272D;
      font-size: 1.3rem;
      line-height: 2rem;
      text-align: left;
      margin: 10px 0;
    }

    .mobile-flashlight-sub-title {
      color: #20272D;
      font-size: 1.3rem;
      line-height: 2rem;
      text-align: left;
      margin: 10px 0;
    }

    .mobile-flashlight-enter-button {
      height: 50px;
      display: flex;
      font-size: 1.5em;
      justify-content: center;
      margin: 30px 0;
      animation: fade 1s infinite;
      -webkit-animation: fade 1s infinite;
      span {
        color: #20272D;
        line-height: 50px;
        text-decoration-line: underline;
      }
      img {
        height: 10px;
        margin: 20px 5px;
      }
    }
  }
  .mobile-flashlight-container-en {
    bottom: 40vh;
    .mobile-flashlight-title {
      color: #20272D;
      font-size: 1.1rem;
      line-height: 1.5rem;
      text-align: left;
      margin: 10px 0;
    }
    .mobile-flashlight-sub-title {
      color: #20272D;
      font-size: 1.1rem;
      line-height: 1.5rem;
      text-align: left;
      margin: 10px 0;
    }
  }
  .enter-button {
    position: absolute;
    width: 100vw;
    height: 30vh;
    //max-height: 40px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    img {
      height: 100%;
      width: auto;
      margin: auto;
      animation: fade 1s infinite;
      -webkit-animation: fade 1s infinite;
    }
  }
  .flashlight {
    position: absolute;
    left: -50%;
    right: -50%;
    top: -50%;
    bottom: -50%;
    width: 200%;
    height: 200%;
  }
}
</style>
