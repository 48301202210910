<template>
  <div class="way-home-1">
    <div class="left-block">
      <div class="intercom-container">
        <img src="../../../assets/images/intercom.svg" alt="對講機">
        <div v-if="getCurrentHighlightCollection" class="intercom-collection">
          <img :src="areaImgIcon[getCurrentHighlightCollection.CollectionArea]" alt="O">
          <span>{{ getCurrentHighlightCollection.CollectionNo + '/' + getHighlightCollectionTitle(getCurrentHighlightCollection) }}</span>
        </div>
        <div class="up-control" @click="changeCollection(-1)"></div>
        <div class="down-control" @click="changeCollection(1)"></div>
        <div class="enter-control" @click="goCollection(currentCollectionIndex)"></div>
        <div class="play-control">
          <el-button type="text" class="font-weight-thin" @click="handlePlayAudio()">{{ getPlayTitle }}</el-button>
        </div>
      </div>
    </div>
    <div class="right-block">
      <div :id="`collection_${collectionIndex}`" v-for="(collection, collectionIndex) in collectionList" :key="`collection_${collection.CollectionId}`" class="collections-list-block font-weight-regular" :class="currentCollectionIndex.toString() ===  collectionIndex.toString() ? 'collection-highlight' : ''">
        <div class="collection-area">
          <span>{{ collection.CollectionNo }}</span>
        </div>
        <div class="collection-title" :class="{ 'collection-title-no-sub': !(collection.CollectionDetails[0].SubTitle && collection.CollectionDetails[0].SubTitle.length > 0) }" @click="goCollection(collectionIndex)">
          <span>{{ getTitle(collection) }}</span>
          <span
              v-if="collection.CollectionDetails[0].SubTitle && collection.CollectionDetails[0].SubTitle.length > 0"
              class="collection-subtitle font-weight-light">
            {{ getSubTitle(collection) }}
          </span>
        </div>
        <div class="collection-collect">
          <img v-if="userCollectList.includes(collection.CollectionId)" src="../../../assets/images/collect.svg" alt="點擊取消收藏" @click="handleCollect(collection, false)">
          <img v-else src="../../../assets/images/uncollect.svg" alt="點擊收藏" @click="handleCollect(collection, true)">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

import router from '../../../router'

export default {
  name: 'WayHome1',
  components: {
  },
  data() {
    return {
      userCollectList: [],
      collectionList: [],
      currentCollectionIndex: undefined,
      areaImgIcon: {
        'O': require('../../../assets/images/icon-O.png'),
        'A': require('../../../assets/images/icon-A.png'),
        'B': require('../../../assets/images/icon-B.png'),
        'C': require('../../../assets/images/icon-C.png'),
      },
      audioPlayer: undefined,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  computed: {
    /***
     *
     * @return {*}
     */
    getCurrentHighlightCollection() {
      return this.collectionList[this.currentCollectionIndex]
    },
    getPlayTitle() {
      return this.currentLanguageKey.includes('ZH_') ? '按下聆聽' : 'Play'
    }
  },
  filters: {
  },
  mounted() {
    this.userCollectList = this.$store.state.userCollectionList
    this.$store.commit('LoadingOpen')
    this.$store.dispatch('GetCollectionList').then(res => {
      this.$store.commit('LoadingClose')
      if (res.success) {
        try {
          this.currentCollectionIndex = 0
          this.collectionList = res.collections.sort((a, b) => a.CollectionNo > b.CollectionNo ? 1 : -1)
          this.collectionList.forEach(x => {
            x.IsCollect = this.userCollectList.includes(x.CollectionId)
          })
        } catch (e) {
          alert(e.toString())
        }
      }
    })
  },
  beforeDestroy() {
    if (this.audioPlayer) {
      this.audioPlayer.pause()
      this.audioPlayer = undefined
    }
  },
  methods: {
    getTitle (collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.Title
    },
    getSubTitle (collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.SubTitle
    },
    getHighlightCollectionTitle (collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      console.log(detail)
      if (detail === null) {
        return ''
      } else if (detail.SubTitle && detail.SubTitle.length > 0) {
        return detail.SubTitle
      } else {
        return detail.Title
      }
    },
    changeCollection(index) {
      const beforeIndex = this.currentCollectionIndex
      this.currentCollectionIndex = ((this.currentCollectionIndex + index) + this.collectionList.length) % this.collectionList.length
      if (this.isElementVisible(document.getElementById(`collection_${this.currentCollectionIndex}`)) === false) {
        document.getElementById(`collection_${this.currentCollectionIndex}`).scrollIntoView({
          behavior: 'smooth', // 使用平滑滾動
          block: beforeIndex > this.currentCollectionIndex ? 'start' : 'end'
        });
      }
    },
    handleCollect(collection, isCollect) {
      // console.log(`handleCollect : ${JSON.stringify(collection)} | ${isCollect}`)
      if (isCollect && this.userCollectList.includes(collection.CollectionId) === false) {
        this.userCollectList.push(collection.CollectionId)
        collection.IsCollect = true
      } else if (isCollect === false && this.userCollectList.includes(collection.CollectionId)) {
        const index = this.userCollectList.indexOf(collection.CollectionId)
        this.userCollectList.splice(index, 1)
        collection.IsCollect = false
      }
      this.$store.commit('SetUserCollected', this.userCollectList)
    },
    goCollection (collectionIndex) {
      if (collectionIndex === this.currentCollectionIndex) {
        router.push({
          name: 'DesktopWayHome2',
          params: {
            id: this.collectionList[collectionIndex].CollectionId
          }
        })
      } else {
        this.currentCollectionIndex = collectionIndex
      }
    },
    isElementVisible(el) {
      const rect = el.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;

      // 判斷元素是否在可視區域內
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= windowHeight &&
          rect.right <= windowWidth
      );
    },
    // handlePlayAudio() {
    //   if (this.audioPlayer) {
    //     this.audioPlayer.pause()
    //     this.audioPlayer = undefined
    //   }
    //   const collection = this.collectionList[this.currentCollectionIndex]
    //   this.$store.commit('AddAudioRecord', collection.CollectionId)
    //   const _this = this
    //   this.audioPlayer = new Audio(`https://homing.ntsec.gov.tw/files/${collection.CollectionDetails[0].AudioFilePath}`)
    //   this.$store.commit('LoadingOpen')
    //   this.audioPlayer.onloadedmetadata = function() {
    //     _this.$store.commit('LoadingClose')
    //   };
    //   this.audioPlayer.onplay = function() {
    //     console.log("Video is playing");
    //   };
    //   this.audioPlayer.onerror = function() {
    //     _this.$store.commit('LoadingClose')
    //     alert("Error! Something went wrong");
    //   };
    //   this.audioPlayer.play()
    // },
    handlePlayAudio() {
      if (this.$store.getters.isAudioPlaying) {
        this.$store.dispatch('PauseAudioPlayer')
      }
      const collection = this.collectionList[this.currentCollectionIndex]
      this.$store.commit('LoadingOpen')
      this.$store.dispatch('PlayAudioPlayer', {
        collectionData: collection,
        collectionId: collection.CollectionId,
        lang: this.currentLanguageKey,
        autoPlay: true
      }).then(res => {
        this.$store.commit('LoadingClose')
        if (res.success) {
          // this.$store.commit('AddAudioRecord', collection.CollectionId)
        } else {
          alert(res.message)
        }
      })
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.way-home-1 {
  position: relative;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #07DB95;
  background-image: url("../../../assets/images/bg.png");
  background-size: cover;
  overflow: hidden;
  display: flex;
  .left-block {
    position: relative;
    width: 50%;
    height: 100%;
    .intercom-container {
      position: relative;
      height: 100%;
      margin: 15% auto;
      width: 46.5vh;
      img {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .intercom-collection {
        position: absolute;
        left: 5%;
        right: 2%;
        width: 42%;
        top: 38%;
        margin: auto;
        //border: 1px solid white;
        display: inline-grid;
        text-align: left;
        img {
          width: 50px;
          margin: auto;
        }
        span {
          margin-top: 20px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2; /* 显示的行数 */
          line-height: 25px; /* 行高 */
          max-height: 50px; /* 总高度（行高 * 行数） */
        }
      }
      .up-control {
        position: absolute;
        left: 12%;
        top: 62%;
        width: 27%;
        height: 100px;
        margin: auto;
        cursor: pointer;
        //background-color: rgba(255,255,255,0.6);
      }
      .down-control {
        position: absolute;
        left: 39%;
        top: 62%;
        width: 25%;
        height: 100px;
        margin: auto;
        cursor: pointer;
        //background-color: rgba(255,255,255,0.6);
      }
      .enter-control {
        position: absolute;
        left: 64%;
        top: 62%;
        width: 27%;
        height: 100px;
        margin: auto;
        cursor: pointer;
        //background-color: rgba(255,255,255,0.6);
      }
      .play-control {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 18%;
        .el-button {
          color: #07DB95;
          font-size: 20px;
          letter-spacing: 10px;
          background-color: black;
        }
      }
    }
  }
  .right-block {
    position: relative;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #1B2228;
    overflow-y: scroll;
    .collections-list-block {
      display: flex;
      height: 70px;
      color: #CCDBCC;
      padding: 40px;
      cursor: pointer;
      .collection-area {
        width: 20%;
        line-height: 70px;
        text-align: right;
        span {
          margin-right: 20px;
          font-weight: bolder;
          font-size: 1.5rem;
        }
      }
      .collection-title {
        width: 60%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          font-size: 1.2rem;
        }
        .collection-subtitle {
          font-size: 1.5rem;
        }
      }
      .collection-title-no-sub {
        span {
          font-size: 1.5rem;
        }
      }
      .collection-collect {
        width: 20%;
        img {
          height: 46px;
          margin: 12px auto;
          cursor: pointer;
          pointer-events: auto;
        }
      }
    }
    .collection-highlight {
      background-color: #5E927A;
    }
    //.collections-list-block:hover {
    //  background-color: #5E927A;
    //}
  }
}
</style>
