<template>
  <div>
    <video id="video" class="camera"></video>
    <div v-if="!isDetected" class="compare-container">
      <img id="CameraImage" :src="cameraImage" style="visibility: hidden" alt>
      <img id="CompareImage" src="../../../../assets/images/ar-cuttle-compare.jpg" style="visibility: hidden" alt>
      <canvas id="CameraCanvas"></canvas>
      <!--      <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; width: 300px; height: 50px; color: red; font-size: 30px">-->
      <!--        相似度: {{ similarity }}-->
      <!--      </div>-->
    </div>
    <div v-else class="ar-container">
      <div v-if="showSnakeEgg === false">
        <img v-if="currentLanguageKey.includes('ZH_')" class="snake-img" src="../../../../assets/images/ar-snake/ar-snake.svg" alt>
        <img v-else class="snake-img" src="../../../../assets/images/ar-snake/ar-snake-en.svg" alt>
      </div>
      <div v-else>
        <img v-if="currentLanguageKey.includes('ZH_')" class="snake-img" src="../../../../assets/images/ar-snake/ar-snake-egg.svg" alt>
        <img v-else class="snake-img" src="../../../../assets/images/ar-snake/ar-snake-egg-en.svg" alt>
      </div>
      <div class="snake-change-box">
        <el-button :class="{ 'active': showSnakeEgg === false }" @click="showSnakeEgg = false">{{ getTitleByLanguage('內臟') }}</el-button>
        <el-button :class="{ 'active': showSnakeEgg }" @click="showSnakeEgg = true">{{ getTitleByLanguage('卵') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArSnakeMixin from "@/mixins/ar-snake-mixin";

export default {
  name: "ARSnake",
  mixins: [ArSnakeMixin],
  data() {
    return {
      showSnakeEgg: false,
      currentLanguageKey: this.$store.getters.languageKey
    }
  }
}
</script>

<style lang="scss" scoped>
.camera {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
}
.compare-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.ar-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  .snake-img {
    width: 40%;
    height: auto;
    margin: 10vh auto;
  }
  .snake-change-box {
    position: fixed;
    right: 40px;
    top: 40px;
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      height: 55px;
      margin: 5px;
      font-size: 1.3rem;
      color: #CCDBCC;
      background: rgba(7, 219, 149, 0.1);
      border: 1px solid #07DB95;
      border-radius: 10px;
    }
    button.active {
      background: rgba(7, 219, 149, 1);
      color: #20272D;
    }
  }
}
</style>
