<template>
  <div class="desktop-search-collection">
    <div v-if="isMobile">
      <div class="desktop-search-box" style="max-width: 90%">
        <div class="desktop-search-box-title font-weight-medium" style="font-size: 1.4rem; line-height: 30px; margin-bottom: 10px">
          <span>{{ getTitle }}</span>
        </div>
        <div class="desktop-search-box-body" style="display: flex; height: 50px">
          <input id="search-input" type="number" pattern="[0-9]*" v-model="searchNo" maxlength="5" style="padding: unset"/>
          <div @click="handleSubmit" style="width: 76px">
            <img src="../../../assets/images/icon-arrow-right.svg" style="margin: 17px 10px" alt>
          </div>
        </div>
      </div>
      <div
          class="desktop-search-box-close-button"
          @click="$emit('close')"
          style="width: 30px; height: 30px; top: 30px; right: 30px;">
        <img src="../../../assets/images/icon-menu-close.svg" alt>
      </div>
    </div>
    <div v-else>
      <div class="desktop-search-box">
        <div class="desktop-search-box-title font-weight-medium">
          <span>{{ getTitle }}</span>
        </div>
        <div class="desktop-search-box-body">
          <input id="search-input" type="number" v-model="searchNo" maxlength="5"/>
          <div @click="handleSubmit">
            <img src="../../../assets/images/icon-arrow-right.svg" alt>
          </div>
        </div>
      </div>
      <div
          class="desktop-search-box-close-button"
          @click="$emit('close')">
        <img src="../../../assets/images/icon-menu-close.svg" alt>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "WayHomeSearchCollection",
  data() {
    return {
      searchNo: undefined,
      isMobile: this.$store.getters.isMobile
    }
  },
  mounted() {
    document.getElementById('search-input').focus()
  },
  computed: {
    getTitle() {
      if (this.$store.getters.languageKey.includes('ZH_')) {
        return '導覽編號'
      } else {
        return 'Audio Number'
      }
    }
  },
  methods: {
    handleSubmit() {
      if (!this.searchNo || this.searchNo.trim().length === 0) {
        return
      }
      console.log(this.searchNo)
      if (/^\d+$/.test(this.searchNo) === false) {
        // if language is chinese then show chinese alert, else show english alert
        if (this.$store.getters.languageKey.includes('ZH_')) {
          alert('請輸入數字')
        } else {
          alert('Please enter a number')
        }
        return
      }
      this.$store.commit('LoadingOpen')
      this.$store.dispatch('GetCollectionList').then(res => {
        this.$store.commit('LoadingClose')
        if (res.success) {
          const filter = res.collections.filter(x => {
            if (x.CollectionNo === this.searchNo) {
              return true
            } else {
              try {
                const collectionNo = parseInt(x.CollectionNo)
                const inputNo = parseInt(this.searchNo)
                return collectionNo === inputNo
              } catch (e) {
                return  false
              }
            }
          })[0]
          if (filter) {
            this.$emit('searched', filter)
          } else {
            // if language is chinese then show chinese alert, else show english alert
            if (this.$store.getters.languageKey.includes('ZH_')) {
              alert('查無導覽，請重新輸入')
            } else {
              alert('No collection found, please re-enter')
            }
            this.searchNo = undefined
            document.getElementById('search-input').focus()
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop-search-collection {
  background: #CCDBCC;
  width: 100vw;
  height: 100vh;
  .desktop-search-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50vh;
    margin: auto;
    height: 130px;
    width: 500px;
    max-width: 80%;
    text-align: left;
    .desktop-search-box-title {
      color: #20272D;
      font-size: 2.3rem;
      line-height: 50px;
      margin-bottom: 20px;
    }
    .desktop-search-box-body {
      display: flex;
      height: 60px;
      input {
        background: #EFE8DD;
        border: 1px solid #D9D3C9;
        height: 100%;
        padding: 0 20px;
        font-size: 1.5rem;
        flex: 1;
      }
      img {
        height: 16px;
        margin: 22px;
      }
    }
  }
  .desktop-search-box-close-button {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 40px;
    height: 40px;
    img {
      width: 100%;
    }
  }
}
</style>
