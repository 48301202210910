<template>
  <div class="way-home-detail">
    <div v-if="collection" class="detail-container">
      <el-row>
        <el-col :span="12">
          <div class="detail-mainTitle">
            <div class="detail-title">
              <span>{{ getTitle(collection) }}</span>
            </div>
            <div class="detail-professionalName">
              <span>{{ getProfessionalName(collection) }}</span>
            </div>
            <div class="detail-highlightContent">
              <span>{{ getHighlightContent(collection) }}</span>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="detail-image-block" :class="{ 'detail-image-block-scale': scaleDetailImg}">
            <img class="scale-img" src="../../../assets/images/icon-detail-image-scale.svg" @click="scaleDetailImg = !scaleDetailImg" alt>
            <img class="detail-img" :src="showImage" alt>
            <span>{{ showImageName }}</span>
          </div>
        </el-col>
      </el-row>
      <div class="detail-mainContent">
        <span v-html="getMainContent(collection).replaceAll('\n', '<br>')"></span>
      </div>
      <div class="mobile-left-top-back-button" @click="goBack">
        <img src="../../../assets/images/icon-arrow-left-green.svg" alt>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../../../router'
import axios from "axios";

export default {
  name: 'WayHomeDetail',
  components: {
  },
  props: {
    dataId: {
      type: Number
    }
  },
  computed: {
    getDetail() {
      return this.collection ? this.collection.CollectionDetails.filter(d => d.LanguageKey === this.$store.getters.languageKey)[0] : null
    },
    getDetailImage() {
      const detail = this.getDetail
      if (detail && detail.CollectionDetailAudios.length > 0) {
        return `https://homing.ntsec.gov.tw/files/${detail.CollectionDetailAudios[0].ImagePath}`
      }
      return ''
    },
    getDetailImageIntro() {
      const detail = this.getDetail
      if (detail && detail.CollectionDetailAudios.length > 0) {
        return detail.CollectionDetailAudios[0].Introduction
      }
      return ''
    },
    showImage() {
      return (this.$store.getters.playingCollectionId === parseInt(this.collectionId) && this.$store.getters.showAudioImage && this.$store.getters.showAudioImage.ImagePath.trim().length > 0) ?
          ('https://homing.ntsec.gov.tw/files/' + this.$store.getters.showAudioImage.ImagePath) : this.getDetailImage
      // 'https://homing.ntsec.gov.tw/files/' + filter.ImagePath
    },
    showImageName() {
      return (this.$store.getters.showAudioImage) ?
          (this.$store.getters.showAudioImage.Introduction) : this.getDetailImageIntro
    }
  },
  watch: {
  },
  data() {
    return {
      collectionId: undefined,
      collection: undefined,
      scaleDetailImg: false,
      currentLanguageKey: this.$store.getters.languageKey
    }
  },
  mounted () {
    if (this.dataId !== null) {
      this.collectionId = this.dataId
    } else if (this.$route.params.id) {
      this.collectionId = this.$route.params.id
    }

    if (this.collectionId) {
      axios.get('https://homing.ntsec.gov.tw/api/Collection/Info/' + this.$route.params.id).then(res => {
        console.log(res)
        this.collection = res.data.Result
      })
    }
  },
  methods: {
    getTitle (collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      if (detail === null) {
        return ''
      }
      if (detail.SubTitle !== null && detail.SubTitle.trim().length > 0) {
        return detail.SubTitle
      }
      return detail.Title
    },
    getProfessionalName(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.ProfessionalName
    },
    getHighlightContent(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.HighlightContent
    },
    getMainContent(collection) {
      const detail = collection.CollectionDetails.filter(c => c.LanguageKey === this.currentLanguageKey)[0]
      return detail === null ? '' : detail.MainContent
    },
    goBack() {
      if (this.dataId) {
        this.$emit('close')
      } else {
        router.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.way-home-detail {
  position: relative;
  background-color: #CCDBCC;
  width: 100%;
  min-height: 100vh;
  padding: 200px 0;
  .detail-container {
    width: 95%;
    max-width: 786px;
    margin: auto;
    text-align: left;
    .detail-mainTitle {
      //height: calc(95vw * 0.35);
      min-height: 320px;
      .detail-title {
        //font-family: "Noto Sans TC", sans-serif ;
        font-weight: 600;
        font-size: 33px;
        color: #20272D;
        //width: 50%;
      }
      .detail-professionalName {
        font-weight: 400;
        font-size: 20px;
        color: #20272D;
        margin-top: 10px;
        //width: 50%;
      }
      .detail-highlightContent {
        font-weight: 600;
        font-size: 24px;
        color: #20272D;
        text-decoration: underline;
        text-decoration-color: #07DB95;
        text-underline-offset: 0.2em;
        margin-top: 30px;
        //width: 50%;
      }
    }
    .detail-image-block {
      position: absolute;
      right: 0;
      top: 0;
      background-color: white;
      width: calc(95vw * 0.4);
      height: calc(95vw * 0.35);
      display: flex;
      flex-direction: column;
      max-width: 375px;
      max-height: 300px;
      .detail-img {
        background: black;
        margin: 10px 10px 0 10px;
        width: calc(100% - 20px);
        height: calc(100% - 44px);
        object-fit: contain;
      }
      .scale-img {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      span {
        line-height: 30px;
        padding: 2px 10px;
        min-height: 30px;
      }
    }
    .detail-image-block-scale {
      position: fixed;
      //width: calc(80vw);
      //height: calc(80vh);
      //left: 10vw;
      //right: 10vw;
      //top: 10vh;
      //bottom: 10vh;
      animation-name: open-img-scale;
      animation-duration: 0.5s;
      max-width: unset;
      max-height: unset;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    @keyframes open-img-scale {
      0%{
        width: calc(95vw * 0.4);
        height: calc(95vw * 0.35);
        //left: 0;
        right: 0;
        top: 0;
        margin: auto;
        opacity: 0;
        //bottom: 0;
      }
      30% {
        opacity: 0.3;
      }
      100%{
        width: 80vw;
        height: 80vh;
        right: 10vw;
        top: 10vh;
        //bottom: 10vh;
        opacity: 1;
      }
    }
    .detail-mainContent {
      font-weight: 400;
      font-size: 22px;
      color: #20272D;
      margin-top: 80px;
      width: 100%;
    }
  }
}
</style>
